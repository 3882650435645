import { render, staticRenderFns } from "./MyProvidersAssignments.vue?vue&type=template&id=35b43ab2&scoped=true&"
import script from "./MyProvidersAssignments.vue?vue&type=script&lang=js&"
export * from "./MyProvidersAssignments.vue?vue&type=script&lang=js&"
import style0 from "./MyProvidersAssignments.vue?vue&type=style&index=0&id=35b43ab2&prod&scoped=true&lang=css&"
import style1 from "./MyProvidersAssignments.vue?vue&type=style&index=1&id=35b43ab2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35b43ab2",
  null
  
)

export default component.exports